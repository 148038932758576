import jquery from "jquery"
window.$ = window.jQuery = jquery

$(function () {
  AOS.init({
    startEvent: 'load',
  })
  

  $('[data-aos]').parent().addClass('hideOverflowOnMobile');

  $('#main-open-carousel').carousel({
    pause: false,
    interval: 5000, //5000
  })

  $('#main-carousel').carousel({
    interval: 4000, //4000
    pause: false,
    nextWhenVisible: false,
  })

  $('#clients-carousel').carousel({
    interval: 5000,
    pause: false,
    nextWhenVisible: false,
  })

  
})

window.onscroll = function () {
  myFunction()
}

let header = document.querySelector('.header')
let sticky = header.offsetTop

function myFunction() {
  $('.nav-small').removeClass('d-flex d-lg-none')
  if (window.pageYOffset >= sticky) {
    header.classList.add('sticky')
  } else {
    header.classList.remove('sticky')
  }
}
